import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { WORKSPACE } from 'constants/index';

import { changeIsOnlyUserProfile, getUser } from 'store/user';
import {
  fetchCurrentWorkspace,
  fetchPersonalWorkspace,
  fetchWorkspaces,
  getAllWorkspaces,
  getIsCurrentWorkspaceLoaded,
  getIsWorkspacesLoaded,
  setActiveId
} from 'store/workspace';

import getActiveWorkspaces from 'utils/get-active-workspaces';

import useWorkspacesStorage from './use-workspaces-storage';

export const GetWorkspacesProvider = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const workspaces = useSelector(getAllWorkspaces);

  const isCurrentWorkspaceLoaded = useSelector(getIsCurrentWorkspaceLoaded);
  const isWorkspacesLoadeed = useSelector(getIsWorkspacesLoaded);

  const { params } = useRouteMatch('/:workspaceId');

  useWorkspacesStorage();

  const storageWorkspaceId = localStorage.getItem(WORKSPACE);

  const workspaceId =
    params.workspaceId && !Number.isNaN(Number(params.workspaceId))
      ? params.workspaceId
      : storageWorkspaceId;

  const fetch = useCallback(async () => {
    let fetchedWorkspace;
    let hasIncorrectWorkspaceId = false;

    if (+workspaceId !== user.personalWorkspaceId) {
      try {
        fetchedWorkspace = await dispatch(
          fetchCurrentWorkspace({ id: workspaceId })
        );
      } catch (error) {
        if (error.response && error.response.status === 404) {
          hasIncorrectWorkspaceId = true;
        }
      }
    }

    if (fetchedWorkspace) {
      dispatch(setActiveId({ workspaceId: fetchedWorkspace.id }));
    }

    if (hasIncorrectWorkspaceId) {
      dispatch(fetchWorkspaces());

      return;
    }

    if (!fetchedWorkspace) {
      dispatch(changeIsOnlyUserProfile(true));
      dispatch(setActiveId({ workspaceId: user.personalWorkspaceId }));

      await dispatch(
        fetchPersonalWorkspace({
          id: user.personalWorkspaceId,
          isCurrent: true
        })
      );
    }

    dispatch(
      fetchWorkspaces({
        personalWorkspaceId: user.personalWorkspaceId,
        withPersonal: !!fetchedWorkspace
      })
    );
  }, [dispatch, user.personalWorkspaceId, workspaceId]);

  useEffect(() => {
    if (user.id && !isCurrentWorkspaceLoaded && !isWorkspacesLoadeed) {
      fetch();
    }
  }, [fetch, isCurrentWorkspaceLoaded, isWorkspacesLoadeed, user.id]);

  useEffect(() => {
    const isOnlyUserProfile =
      user.personalWorkspaceId === +params.workspaceId &&
      !getActiveWorkspaces(workspaces).length;
    dispatch(changeIsOnlyUserProfile(isOnlyUserProfile));

    if (isOnlyUserProfile) {
      dispatch(setActiveId({ workspaceId: user.personalWorkspaceId }));
    }
  }, [user, workspaces, params, dispatch]);

  return children;
};

export default GetWorkspacesProvider;
